<template>
  <div v-bind="$attrs">
    <div :class="{ 'flex flex-1 flex-col': !props.withBackground }">
      <div
        v-if="date || (props.readTime && props.addMoreInfo) || tag"
        :style="{ minHeight: '24px' }"
        class="relative mt-2.5 flex items-center lg:static lg:min-h-0 xl:mb-3 xl:mt-0"
        :class="{
          'order-1 justify-end text-blue-100 lg:justify-between xl:order-none':
            !props.withBackground,
          'justify-between': props.withBackground,
        }"
      >
        <span
          v-if="date"
          class="relative text-sm lg:!static"
          :class="{
            '!absolute right-[calc(100%+2.5rem)]': !props.withBackground,
          }"
          :title="props.datetime"
        >
          <time :datetime="props.datetime">{{ date }}</time>
        </span>
        <span
          v-if="props.readTime && props.addMoreInfo"
          class="hidden items-center text-sm lg:mb-2.5 lg:flex xl:mb-0"
        >
          <UiIcon
            name="clock"
            :width="25"
            :height="25"
            class="mr-1"
          />
          {{ props.readTime }}
          {{ props.labels?.readTime }}
        </span>
        <UiTag
          v-if="tag"
          :tag="tag"
          :show-hash="true"
          :variant="props.withBackground ? 'white' : 'outlet'"
        />
      </div>
      <p
        :style="{ minHeight: '65px' }"
        class="line-clamp-4 text-base font-bold lg:line-clamp-none lg:min-h-0 xl:text-xl"
        data-test="ui-news-list-item-text-title"
        :class="{
          'mt-0 flex-1 text-primary xl:mb-6': !props.withBackground,
          'mt-3.5 text-white group-hover:underline lg:mb-3.5 lg:text-xl xl:mt-0':
            props.withBackground,
        }"
      >
        {{ props.title }}
      </p>
      <div class="hidden text-base font-normal xl:block">
        <div
          :class="[
            props.withBackground ? 'xl:line-clamp-5' : 'xl:line-clamp-3',
          ]"
        >
          <slot
            v-if="props.teaser"
            v-bind="{ teaser: props.teaser }"
          >
            {{ props.teaser }}
          </slot>
        </div>
      </div>
    </div>
    <slot
      name="actions"
      v-bind="{
        withBackground: props.withBackground,
        buttonText: props.moreLink,
        slug: props.slug,
      }"
    >
      <div class="mt-6 hidden text-sm xl:block">
        <UiButton
          :variant="props.withBackground ? 'transparent' : 'circle'"
          :with-animation="!Boolean(props.withBackground)"
          :class="
            props.withBackground
              ? 'group-hover:border-white group-hover:text-white'
              : 'hover:bg-primary hover:text-white'
          "
        >
          {{ props.moreLink }}
        </UiButton>
      </div>
    </slot>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import UiButton from '../../../UiButton/UiButton.vue'
import UiTag from '../../../UiTag/UiTag.vue'
import UiIcon from '../../../UiIcon/UiIcon.vue'
import type { UiNewsItemCommon } from '../UiNewsListItem.types'

interface UiNewsListItemText extends UiNewsItemCommon {
  withBackground?: boolean
}

const props = withDefaults(defineProps<UiNewsListItemText>(), {
  teaser: '',
  datetime: '',
  datetimeShort: '',
  readTime: undefined,
  moreLink: '',
  withBackground: false,
  addMoreInfo: true,
  slug: '',
  labels: null,
})

const date = computed(() => props.datetime?.replace(/-/g, '.'))

const tag = computed(() => {
  return props.tags?.[0]?.title
})
</script>
